import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export default function HowItWorksLabel() {
  const icon = useStaticQuery(query)
  return <img src={icon.file.publicURL} alt={icon.name} />
}

const query = graphql`
  query howItWorksLabelQuery {
    file(relativePath: { eq: "employer/how-it-works.svg" }) {
      name
      publicURL
    }
  }
`
