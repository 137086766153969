import React from 'react'
import { TextColor } from './Types'

function EnrollHeadline({ text = 'white', children }: EnrollHeadlineProps) {
  return (
    <h2
      className={`text-gp-${text} text-3xl tracking-tight leading-snug md:text-4xl block`}
    >
      {children}
    </h2>
  )
}

interface EnrollHeadlineProps {
  text?: TextColor
  children: React.ReactNode
}

export default EnrollHeadline
