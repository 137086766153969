import React from 'react'
import HeroComponent from '../HeroComponent'
import DhlHeroContent from './DhlHeroContent'
import DhlHeroImage from './DhlHeroImage'
import DhlLogo from './DhlLogo'

export default function DhlHero({ ctaLink }: Props) {
  return (
    <HeroComponent
      containerType="tertiary"
      Logo={DhlLogo}
      Image={DhlHeroImage}
      content={<DhlHeroContent ctaLink={ctaLink} />}
    />
  )
}

type Props = {
  ctaLink: string
}
