import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeroImage from '../HeroImage'

export default function DhlHeroImage() {
  const queryRes = useStaticQuery(query)

  return <HeroImage largeImage={queryRes.lg} smallImage={queryRes.md} />
}

const query = graphql`
  query DhlHeroImageQuery {
    lg: file(name: { eq: "sleep-back-pain-carrying-lg" }) {
      name
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    md: file(name: { eq: "sleep-back-pain-carrying-md" }) {
      name
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
