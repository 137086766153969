import React from 'react'
import Img from 'gatsby-image'
import classNames from 'classnames'

export default function HeroImage({ largeImage, smallImage, alt }: Props) {
  const imgClass = classNames('', {
    'hidden lg:block': smallImage
  })

  return (
    <div className="w-full">
      <Img
        className={imgClass}
        fluid={largeImage.childImageSharp.fluid}
        alt={alt || largeImage.name}
      />
      {smallImage && (
        <Img
          className="block lg:hidden"
          fluid={smallImage.childImageSharp.fluid}
          alt={alt || smallImage.name}
        />
      )}
    </div>
  )
}

type Props = {
  largeImage: Image
  smallImage?: Image
  alt?: string
}

interface Image {
  childImageSharp: {
    fluid: any
  }
  name: string
}
