import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'

export default function ConditionIcon({ slug, linkUrl }: Props) {
  const [iconTitle, setIconTitle] = React.useState('')
  const icons = useStaticQuery(conditionIconsQuery)

  const icon = useMemo(() => {
    switch (slug) {
      case 'back-pain':
      case 'backPain':
        setIconTitle('Back Pain')
        return icons.backPain
      case 'ibs':
        setIconTitle('Gut Health')
        return icons.ibs
      case 'sleep':
        setIconTitle('Sleep Issues')
        return icons.sleep
      case 'long-covid':
      case 'longCovid':
        setIconTitle('Long Covid')
        return icons.longCovid
      case 'mental-health':
      case 'mentalHealth':
        setIconTitle('Mental Health')
        return icons.mentalHealth
      default:
        return null
    }
  }, [slug, icons])

  const Container = linkUrl ? Link : 'div'

  if (!icon) {
    return null
  }

  return (
    <Container
      to={linkUrl!}
      className="flex flex-col items-center justify-center w-16 md:w-auto hover:no-underline focus:outline-none"
    >
      <div className="flex justify-center pb-4">
        <img src={icon.publicURL} alt={icon.name} />
      </div>
      <h3 className="w-16 text-sm font-normal leading-tight text-center md:20 md:text-lg ">
        {iconTitle.toUpperCase()}
      </h3>
    </Container>
  )
}

type Props = {
  slug: string
  linkUrl?: string
}

const conditionIconsQuery = graphql`
  query conditionIconsQuery {
    backPain: file(relativePath: { eq: "conditions/icons/back-pain.svg" }) {
      name
      publicURL
    }
    ibs: file(relativePath: { eq: "conditions/icons/ibs.svg" }) {
      name
      publicURL
    }
    sleep: file(relativePath: { eq: "conditions/icons/sleep.svg" }) {
      name
      publicURL
    }
    longCovid: file(relativePath: { eq: "conditions/icons/long-covid.svg" }) {
      name
      publicURL
    }
    mentalHealth: file(
      relativePath: { eq: "conditions/icons/mental-health.svg" }
    ) {
      name
      publicURL
    }
  }
`
