import React from 'react'
import { TextColor } from './Types'

function EmployerHeroBlurb({
  text = 'off-white',
  children
}: EmployerHeroBlurbProps) {
  return (
    <>
      <div className="md:max-w-xl">
        <p className={`text-gp-${text} mb-6 text-xl leading-snug md:mb-8`}>
          Treating back pain, joint pain, sleep, digestive health, and long
          covid - with physical, nutritional and behavioral solutions.
        </p>
        {children ? (
          <p className={`text-gp-${text} mb-6 text-xl leading-snug md:mb-8`}>
            {children}
          </p>
        ) : null}
      </div>
    </>
  )
}

interface EmployerHeroBlurbProps {
  text?: TextColor
  children: React.ReactNode
}

export default EmployerHeroBlurb
