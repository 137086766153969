import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React, { useEffect, useMemo } from 'react'
import { NoIndex } from '../components/NoIndex'
import { EmployerPageProvider } from '../contexts/EmployerPage/employerPageContext'
import FeaturedIn from '../components/Homepage/FeaturedIn'
// Shared
import ConditionStats from '../components/Shared/ConditionStats'
import IntegrativeApproach from '../components/Shared/IntegrativeApproach'
import PersonaCarousel from '../components/Shared/PersonaCarousel'
import MemberResults from '../components/Shared/MemberResults'
import BoxAppCoach from '../components/Shared/BoxAppCoach'
import CaseStudies from '../components/Shared/CaseStudies'
// Employer
import EmployerHero from '../components/Employer/EmployerHero'
import EmployerHeroBlurb from '../components/Employer/EmployerHeroBlurb'
import EmployerHeroLogos from '../components/Employer/EmployerHeroLogos'
import EnrollCta from '../components/Employer/EnrollCta'
import EnrollText from '../components/Employer/EnrollText'
import EnrollHeadline from '../components/Employer/EnrollHeadline'
import EnrollSubheadline from '../components/Employer/EnrollSubheadline'
import EnrollLink from '../components/Employer/EnrollLink'
// Heros
import goodpathHeroOnViolet from '../images/homepage/homepage-hero-alt.png'
import homepageHero from '../images/homepage/homepage-mobile-hero.jpg'
// Logos and Icons
import { useEmployerContext } from '../contexts/Employer/employerProvider'

import EmployerFooter from '../components/Employer/EmployerFooter'
import FixedGetStarted from '../components/Employer/FixedGetStarted'
import YamahaHero from '../components/Employer/heros/yamaha/YamahaHero'
import HowItWorksFloor from '../components/Employer/HowItWorksFloor'
import MapfreHero from '../components/Employer/heros/mapfre/MapfreHero'
import DhlHero from '../components/Employer/heros/dhl/DhlHero'

const EmployerPage = ({ pageContext: { slug, employer } }) => {
  console.log(`EmployerPage employer`, employer)
  const { setEmployerSlug } = useEmployerContext()

  useEffect(() => {
    if (slug && employer) {
      setEmployerSlug(slug)
    }
  }, [slug, employer, setEmployerSlug])

  const { mobileLogo = {}, companyPaymentBlurb, companyName } = employer
  const { fluid: mobileLogoImage, title: mobileLogoTitle } = mobileLogo || {}
  const videoId =
    slug === 'yamaha' ? '1fmkm4m7fu' : slug === 'mapfre' ? 'gum1be7adw' : ''

  const selectConditionLink = useMemo(() => `/select-condition?e=${slug}`, [
    slug
  ])

  const enrollmentDisabled = useMemo(() => {
    switch (slug) {
      case 'dhl-canada':
        return true
      default:
        return false
    }
  }, [slug])

  return (
    <EmployerPageProvider>
      <div>
        <NoIndex />
        <div className="relative flex flex-col">
          <FixedGetStarted
            linkUrl={selectConditionLink}
            linkTitle={
              slug === 'yamaha' || slug === 'mapfre'
                ? 'Start your free benefit'
                : 'Get started'
            }
            companyName={companyName}
            showFullyPaidBy={
              slug === 'yamaha' || slug === 'mapfre' || slug === 'dhl-canada'
            }
            showLoginLink={true}
            active={!enrollmentDisabled}
            autoHide={
              slug === 'yamaha' || slug === 'mapfre' || slug === 'dhl-canada'
            }
          />

          {slug === 'mapfre' ? (
            <MapfreHero ctaLink={selectConditionLink} />
          ) : slug === 'yamaha' ? (
            <>
              <YamahaHero ctaLink={selectConditionLink} />
            </>
          ) : slug === 'dhl-canada' ? (
            <>
              <DhlHero ctaLink={selectConditionLink} />
            </>
          ) : (
            <EmployerHero
              imageDesktop={goodpathHeroOnViolet}
              imageMobile={homepageHero}
            >
              <EmployerHeroLogos>
                <Img
                  className="w-48 md:w-64"
                  fluid={mobileLogoImage}
                  alt={mobileLogoTitle}
                />
              </EmployerHeroLogos>

              <EmployerHeroBlurb>{companyPaymentBlurb}</EmployerHeroBlurb>

              <div className="hidden md:block md:mb-6 md:max-w-xl">
                <div>
                  <Link
                    to={`/select-condition?e=${slug}`}
                    className="inline-flex px-6 py-5 text-xl font-bold transition-all duration-300 rounded bg-gp-off-white text-gp-violet lg:text-2xl lg:px-8 lg:py-5 hover:no-underline hover:bg-gp-slate-30 focus:no-underline focus:bg-gp-slate-30"
                  >
                    Get started
                  </Link>

                  <Link
                    className="block mt-4 mb-2 text-base text-white"
                    to="/login"
                  >
                    Already enrolled? Log in
                  </Link>
                </div>
              </div>
            </EmployerHero>
          )}

          {/* How it works video floor */}
          {videoId && (
            <HowItWorksFloor ctaLink={selectConditionLink} videoId={videoId} />
          )}

          {/* Condition Statistics */}
          {slug === 'yamaha' || slug === 'dhl-canada' || slug === 'mapfre' ? (
            <></>
          ) : (
            <ConditionStats />
          )}

          {/* Integrative Approach */}
          {slug === 'yamaha' || slug === 'mapfre' ? (
            <></>
          ) : (
            <IntegrativeApproach />
          )}

          {/* Persona Carousel */}
          <PersonaCarousel />

          {/* Member Results */}
          <MemberResults />

          {/* Mega section, box, app, coach */}
          <BoxAppCoach />

          {/* Case Studies */}
          <CaseStudies />

          {/* Featured In */}
          {slug === 'mapfre' ? <></> : <FeaturedIn />}

          {/* Enroll Call to action */}
          <EnrollCta>
            <EnrollText>
              <EnrollHeadline>
                <span>Ready to enroll?</span>
              </EnrollHeadline>

              <EnrollSubheadline>
                <span>Take the first steps on a good path</span>
              </EnrollSubheadline>
            </EnrollText>

            {slug === 'yamaha' || slug === 'mapfre' ? (
              <EnrollLink>
                <div className="inline-flex flex-col items-start">
                  <Link
                    to={`/select-condition?e=${slug}`}
                    className="inline-flex px-6 py-5 text-xl font-bold transition-all duration-300 bg-white rounded text-gp-violet lg:text-2xl lg:px-10 lg:py-6 lg:min-h-24 hover:no-underline hover:bg-gp-slate-30 focus:no-underline focus:bg-gp-slate-30"
                  >
                    Start your free benefit
                  </Link>
                  <span className="block py-1 mt-2 mb-2 text-sm text-center text-gp-off-white">
                    Fully paid by {companyName}
                  </span>
                </div>
              </EnrollLink>
            ) : enrollmentDisabled ? (
              <div className="inline-flex flex-col items-start">
                <span className="block py-1 mt-2 mb-2 text-sm text-center text-gp-off-white">
                  The Goodpath pilot program is now full.
                </span>
              </div>
            ) : (
              <EnrollLink>
                <div className="inline-flex">
                  <Link
                    to={`/select-condition?e=${slug}`}
                    className="inline-flex px-6 py-5 text-xl font-bold transition-all duration-300 bg-white rounded text-gp-violet lg:text-2xl lg:px-10 lg:py-6 lg:min-h-24 hover:no-underline hover:bg-gp-slate-30 focus:no-underline focus:bg-gp-slate-30"
                  >
                    Get started
                  </Link>
                </div>
              </EnrollLink>
            )}
          </EnrollCta>

          <div className="flex items-center justify-center p-8 bg-gp-violet-30">
            <span className="max-w-5xl text-2xl leading-snug text-center text-gp-grey-100">
              Having trouble with enrollment? Reach out to&nbsp;
              <a href="mailto:support@goodpath.com?subject=Support%20for%20enrollment">
                support@goodpath.com
              </a>
            </span>
          </div>

          <EmployerFooter />
        </div>
      </div>
    </EmployerPageProvider>
  )
}

export default EmployerPage
