import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export default function WatchHowItWorksLabel() {
  const icon = useStaticQuery(query)
  return <img src={icon.file.publicURL} alt={icon.name} />
}

const query = graphql`
  query watcHowItWorksLabelQuery {
    file(relativePath: { eq: "employer/watch-how-it-works.svg" }) {
      name
      publicURL
    }
  }
`
