import React from 'react'
// import GetStartedButton from '../../GetStartedButton'
import HeroContent from '../HeroContent'
import DhlCta from './DhlCta'
import DhlLogo from './DhlLogo'

export default function DhlHeroContent({ ctaLink }: Props) {
  return (
    <HeroContent
      Logo={DhlLogo}
      title={
        <div className="text-3xl lg:text-4xxl">
          <strong>Whole-person care </strong> <br /> for back & neck pain,
          troubled sleep, or digestive issues
        </div>
      }
      subtitle={
        <>
          Complete care supported by a health coach, accessed online, and with
          treatments shipped to you.&nbsp;
          <strong>Fully-paid by DHL Canada</strong>.
        </>
      }
      Cta={DhlCta}
      // Cta={GetStartedButton}
      ctaLink={ctaLink}
      notice={
        // <div className="pt-6 pb-4">
        <div className="pb-4 -mt-4">
          This pilot program has a&nbsp;<strong>limited number of spots</strong>
          &nbsp;for DHL Canada employees.
        </div>
      }
    />
  )
}

type Props = {
  ctaLink: string
}
