import React from 'react'
import YamahaHeroImage from './YamahaHeroImage'
import HeroComponent from '../HeroComponent'
import YamahaLogo from './YamahaLogo'
import YamahaHeroContent from './YamahaHeroContent'

export default function YamahaHero({ ctaLink }: Props) {
  return (
    <HeroComponent
      containerType="primary"
      Logo={YamahaLogo}
      Image={YamahaHeroImage}
      content={<YamahaHeroContent ctaLink={ctaLink} />}
      widthRatioType='secondary'
    />
  )
}

type Props = {
  ctaLink: string
}
