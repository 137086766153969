import React from 'react'
import { TextColor } from './Types'
// Logos and Icons
// @ts-ignore
import GoodpathLogo from '../../../assets/logos/goodpath-logo-fill.svg'
// @ts-ignore
import PlusFill from '../../../assets/icons/plus-fill.svg'

function EmployerHeroLogos({
  text = 'off-white',
  children
}: EmployerHeroLogosProps) {
  return (
    <>
      <div className="flex flex-col items-center justify-center my-6 space-y-6 md:flex-row md:justify-start md:space-y-0 md:space-x-6">
        <GoodpathLogo className={`text-gp-${text} w-48 fill-current md:w-64`} />

        <PlusFill
          className={`text-gp-${text} w-6 h-6 fill-current md:w-10 md:h-10`}
        />

        {children}
      </div>
    </>
  )
}

interface EmployerHeroLogosProps {
  text?: TextColor
  children: React.ReactNode
}

export default EmployerHeroLogos
