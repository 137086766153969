import React from 'react'

export default function DhlCta() {
  return (
    <div
      style={styles.container}
      className="max-w-xs px-6 py-4 mx-auto mb-8 text-xl leading-normal lg:max-w-sm lg:text-2xl lg:mx-0"
    >
      <div>
        The Goodpath pilot program is now full. Join the waitlist to learn when
        you can get access!
      </div>
      <div class="klaviyo-form-Wb5uGA"></div>
      {/* <div style={styles.strongText} className="font-bold uppercase">
        Check back here soon!
      </div> */}
    </div>
  )
}
const styles = {
  container: {
    // background: '#FFCB05',
    // borderRadius: '0.5rem'
  },
  strongText: {
    color: '#D81635'
  }
}
