import React, { useState, useEffect, useRef } from 'react'

export default function useOnScreen(ref: React.RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false)
  const observer = useRef<any>()

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    )
  }, [])

  useEffect(() => {
    if (!ref.current) return
    observer.current.observe(ref.current)
    return () => {
      observer.current.disconnect()
    }
  }, [observer, ref])

  return isIntersecting
}
