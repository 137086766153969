import React from 'react'
import { BackgroundColor, TextColor } from './Types'

function EnrollCta({
  background = 'violet',
  text = 'white',
  children
}: EnrollCtaProps) {
  return (
    <div className={`bg-gp-${background}`}>
      <div className="max-w-5xl px-4 py-12 mx-auto sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        {children}
      </div>
    </div>
  )
}

interface EnrollCtaProps {
  background?: BackgroundColor
  text?: TextColor
  children: React.ReactNode
}

export default EnrollCta
