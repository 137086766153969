import React from 'react'
import ConditionIcon from './ConditionIcon'
const conditions = ['back-pain', 'sleep', 'ibs', 'long-covid', 'mental-health']

export default function ConditionsStripe({ linkUrl }: { linkUrl?: string }) {
  return (
    <div className="flex justify-around align-middle lg:justify-between">
      {conditions.map((condition) => (
        <ConditionIcon key={condition} slug={condition} linkUrl={linkUrl} />
      ))}
    </div>
  )
}
