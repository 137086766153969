import React from 'react'
import ConditionsStripe from '../../common/conditions/ConditionsStripe'
import WatchHowItWorksLabel from '../WatchHowItWorksLabel'
import HowItWorksLabel from '../HowItWorksLabel'

export default function HeroContent({
  Logo,
  title,
  Cta,
  subtitle,
  notice,
  subNotice,
  conditionStripe,
  ctaColor,
  ctaTextColor,
  ctaLink,
  watchHowItWorksLabel,
  howItWorksLabel
}: Props) {
  return (
    <>
      <div>
        {Logo && (
          <div className="hidden py-12 lg:flex">
            <Logo />
          </div>
        )}
        {title && (
          <h1 className="pb-4 text-4xl lg:text-6xl sm:text-5xl text-primary-base">
            {title}
          </h1>
        )}
        <br />
        {subtitle && (
          <h2 className="text-base leading-normal md:text-2xl text-gp-eel-black">
            {subtitle}
          </h2>
        )}
      </div>
      {Cta && (
        <div>
          <Cta
            ctaLink={ctaLink}
            ctaColor={ctaColor}
            ctaTextColor={ctaTextColor}
          />
        </div>
      )}
      {notice && (
        <div>
          <h3 className="pb-4 text-base leading-normal">{notice}</h3>
        </div>
      )}
      {subNotice && (
        <div>
          <h3 className="pb-4 text-sm leading-normal">{subNotice}</h3>
        </div>
      )}
      {conditionStripe && (
        <div className="max-w-3xl px-0 mx-auto sm:px-8 lg:mx-0 lg:max-w-md lg:px-0">
          <ConditionsStripe linkUrl={ctaLink} />
        </div>
      )}
      {watchHowItWorksLabel && (
        <div className="flex justify-center pt-4 pb-8 lg:pt-8 lg:pb-14 lg:justify-start">
          <WatchHowItWorksLabel />
        </div>
      )}
      {howItWorksLabel && (
        <div className="flex justify-center pt-4 pb-8 lg:pt-8 lg:pb-14 lg:justify-start">
          <HowItWorksLabel />
        </div>
      )}
    </>
  )
}

type Props = {
  Logo?: React.FC
  title?: React.ReactNode
  subtitle?: React.ReactNode
  notice?: React.ReactNode
  subNotice?: React.ReactNode
  Cta?: React.FC<{ ctaLink: string; ctaColor?: string; ctaTextColor?: string }>
  ctaColor?: string
  ctaTextColor?: string
  ctaLink: string
  conditionStripe?: boolean
  watchHowItWorksLabel?: boolean
  howItWorksLabel?: boolean
}
