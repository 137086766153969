import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import { useEmployerPageContext } from '../../contexts/EmployerPage/employerPageContext'
import useOnScreen from '../../utils/useOnScreen'
export default function GetStartedButton({
  ctaLink,
  ctaColor = 'partner-yamaha',
  ctaTextColor = 'white'
}: Props) {
  const ref = React.useRef<any>(null)
  const isVisible = useOnScreen(ref)

  const { setCtaVisible } = useEmployerPageContext()

  useEffect(() => {
    setCtaVisible(!isVisible)
  }, [setCtaVisible, isVisible])

  return (
    <Link
      ref={ref}
      className={`inline-flex items-center justify-center px-8 py-4 my-6 text-sm font-bold leading-none rounded-lg lg:my-10 md:text-xl lg:text-2xl text-${ctaTextColor} bg-${ctaColor}`}
      to={ctaLink}
    >
      GET STARTED
    </Link>
  )
}

type Props = {
  ctaLink: string
  ctaColor?: string
  ctaTextColor?: string
}
