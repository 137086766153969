import React from 'react'
import { BackgroundColor } from './Types'

function EmployerHero({
  background = 'violet',
  children,
  imageDesktop,
  imageMobile
}: EmployerHeroProps) {
  return (
    <>
      <div className={`bg-gp-${background} px-8 lg:py-8`}>
        <div className="relative flex flex-col justify-center mx-auto max-w-7xl xl:min-h-700">
          {children}
        </div>
        <div className="absolute top-0 right-0 mt-8">
          <img
            src={imageDesktop}
            alt="Two women enjoying freedom from chronic pain, hiking through plains."
            className="hidden lg:block lg:max-w-md xl:max-w-3xl"
          />
        </div>
      </div>
      <div className="">
        <img
          src={imageMobile}
          alt="A man in good health after completing his Goodpath program."
          className="w-full lg:hidden"
        />
      </div>
    </>
  )
}

interface EmployerHeroProps {
  background?: BackgroundColor
  children: React.ReactNode
  imageDesktop: string
  imageMobile: string
}

export default EmployerHero
