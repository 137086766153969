import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'

export default function HeroContainer({ type, children }: Props) {
  const queryRes = useStaticQuery(heroBackgroundQuery)

  const icon = useMemo(() => {
    switch (type) {
      case 'primary':
        return queryRes.eclipse1
      case 'secondary':
        return queryRes.eclipse2
      case 'tertiary':
        return queryRes.eclipse3
      default:
        return null
    }
  }, [type, queryRes])

  return (
    <div className="w-full overflow-hidden min-h-100">
      {icon && (
        <img
          src={icon.publicURL}
          alt={icon.name}
          className="absolute right-0 hidden w-1/2 lg:block"
          style={{ zIndex: -1 }}
        />
      )}
      <div className="relative z-10 mx-auto max-w-7xl">{children}</div>
    </div>
  )
}

const heroBackgroundQuery = graphql`
  query heroBackgroundQuery {
    eclipse1: file(name: { eq: "eclipse1" }) {
      name
      publicURL
    }
    eclipse2: file(name: { eq: "eclipse2" }) {
      name
      publicURL
    }
    eclipse3: file(name: { eq: "eclipse3" }) {
      name
      publicURL
    }
  }
`
type Props = {
  type?: 'primary' | 'secondary' | 'tertiary'
  children: React.ReactNode
}
