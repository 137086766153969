import classNames from 'classnames'
import React from 'react'
import HeroContainer from './HeroContainer'

export default function HeroComponent({
  Logo,
  Image,
  content,
  containerType,
  widthRatioType = 'primary'
}: Props) {
  const contentClass = classNames(
    'w-full px-4 text-center lg:pl-12 lg:text-left',
    {
      'lg:w-6/12': widthRatioType === 'primary',
      'lg:w-7/12': widthRatioType === 'secondary'
    }
  )

  const imageClass = classNames(
    'w-full max-w-xl px-1 pb-4 mx-auto lg:mx-0 lg:w-5/12 lg:max-w-full lg:pb-0 lg:flex lg:items-end lg:px-0',
    {
      'lg:w-6/12': widthRatioType === 'primary',
      'lg:w-5/12': widthRatioType === 'secondary'
    }
  )

  return (
    <div>
      <HeroContainer type={containerType}>
        <div className="flex justify-center pt-8 pb-4 lg:hidden">
          <Logo />
        </div>
        <div className="flex flex-col-reverse w-full lg:flex-row">
          <div className={contentClass}>
            {content}
          </div>
          <div className={imageClass}>
            <Image />
          </div>
        </div>
      </HeroContainer>
    </div>
  )
}

type Props = {
  Logo: React.FC
  content: React.ReactNode
  Image: React.FC
  containerType?: 'primary' | 'secondary' | 'tertiary'
  widthRatioType?: 'primary' | 'secondary'
}
