import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState
} from 'react'
export const defaultState = {
  fixedCtaVisible: false
}

const EmployerPageContext = createContext<ContextType>(
  defaultState as ContextType
)

export const EmployerPageProvider = ({ children }: Provider) => {
  const [fixedCtaVisible, setFixedCtaVisible] = useState(false)

  const setCtaVisible = useCallback(
    (visible: boolean) => {
      setFixedCtaVisible(visible)
    },
    [setFixedCtaVisible]
  )

  const contextValue = useMemo(
    () => ({
      fixedCtaVisible,
      setCtaVisible
    }),
    [fixedCtaVisible, setFixedCtaVisible]
  )
  return (
    <EmployerPageContext.Provider value={contextValue}>
      {children}
    </EmployerPageContext.Provider>
  )
}

export const useEmployerPageContext = () => useContext(EmployerPageContext)

type ContextType = {
  fixedCtaVisible: boolean
  setCtaVisible: (visible: boolean) => void
}

type Provider = {
  children: React.ReactNode
}
