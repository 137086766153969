import React from 'react'

function EnrollLink({ children }: EnrollLinkProp) {
  return <div className="flex mt-8 lg:mt-0 lg:flex-shrink-0">{children}</div>
}

interface EnrollLinkProp {
  children: React.ReactNode
}

export default EnrollLink
