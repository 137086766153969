import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

export default function MapfreLogo() {
  const queryRes = useStaticQuery(query)

  return (
    <div className="w-64 md:w-80 lg:w-104">
      <Img
        fluid={queryRes.file.childImageSharp.fluid}
        alt={queryRes.file.name}
      />
    </div>
  )
}

const query = graphql`
  query MapfreLogoQuery {
    file(name: { eq: "mapfreLogo" }) {
      name
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
