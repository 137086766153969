import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { useEmployerPageContext } from '../../contexts/EmployerPage/employerPageContext'

export default function FixedGetStarted({
  linkTitle,
  linkUrl,
  companyName,
  showFullyPaid,
  showLoginLink,
  active = true,
  autoHide
}) {
  const { fixedCtaVisible } = useEmployerPageContext()
  const [init, setInit] = React.useState(false)

  const containerClass = classNames(
    'bottom-0 transition-all duration-150	ease-linear left-0 z-50 flex flex-col items-center justify-center w-full pt-2 pb-4 border-t bg-gp-violet border-gp-grey-20',
    {
      'fixed md:hidden': init || !autoHide,
      hidden: !init && autoHide,
      'mb-0': fixedCtaVisible && autoHide,
      '-mb-80': !fixedCtaVisible && autoHide
    }
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setInit(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  if (!active) return null

  return (
    <div className={containerClass}>
      <div className="w-full px-6 mt-4 ">
        <Link className="w-full btn btn--white" to={linkUrl}>
          {linkTitle}
        </Link>
        {showFullyPaid && (
          <span className="block py-1 mt-2 mb-2 text-sm text-center text-gp-off-white">
            Fully paid by {companyName}
          </span>
        )}
        {showLoginLink && (
          <Link
            className="block py-1 mt-4 mb-2 text-sm text-center text-gp-off-white"
            to="/login"
          >
            Already enrolled? Log in
          </Link>
        )}
      </div>
    </div>
  )
}
