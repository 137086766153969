import React from 'react'
import GetStartedButton from '../../GetStartedButton'
import HeroContent from '../HeroContent'
import MapfreLogo from './MapfreLogo'

export default function MapfreHeroContent({ ctaLink }: Props) {
  return (
    <HeroContent
      Logo={MapfreLogo}
      title={
        <>
          <strong>Whole-person</strong>&nbsp;care <br /> for common conditions
        </>
      }
      subtitle={
        <>
          Complete care supported by a health coach, accessed online, and with
          treatments shipped to you.&nbsp;
          <strong>Fully-paid by MAPFRE</strong>.
        </>
      }
      Cta={GetStartedButton}
      ctaLink={ctaLink}
      conditionStripe
      watchHowItWorksLabel
    />
  )
}

type Props = {
  ctaLink: string
}
