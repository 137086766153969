import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

export default function YamahaLogo() {
  const queryRes = useStaticQuery(yamahaLogoQuery)

  return (
    <div className="w-64 md:w-80 lg:w-104">
      <Img
        fluid={queryRes.file.childImageSharp.fluid}
        alt={queryRes.file.name}
      />
    </div>
  )
}

const yamahaLogoQuery = graphql`
  query yamahaLogoQuery {
    file(name: { eq: "yamahaLogo" }) {
      name
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
