import React from 'react'
import { Link } from 'gatsby'
import goodpathLogo from '../../images/logos/goodpath-logo.svg'

export default function EmployerFooter({
  background = 'bg-gp-magnolia',
  footerExtend = (
    <div className="flex flex-col items-center justify-center mt-1">
      *Goodpath does not provide medical advice, diagnosis or treatment.
    </div>
  )
}) {
  const currentYear =
    process.env.GATSBY_CURRENT_YEAR || new Date().getFullYear()

  return (
    <footer className={`p-8 ${background} mb-28 md:mb-0`}>
      <div className="mx-auto md:max-w-3xl ">
        <div className="flex flex-col mb-16 space-y-16 md:flex-row md:space-y-0 md:space-x-24">
          <div className="w-32 mb-4">
            <img
              src={goodpathLogo}
              alt="Goodpath Logo."
              className="w-48 text-white fill-current md:w-80"
            />
          </div>

          <div className="flex flex-col space-y-6">
            <span className="text-lg bold text-gp-grey-100">Company</span>
            <a
              href="mailto:support@goodpath.com"
              className="block text-sm font-normal text-gp-grey-100"
            >
              Contact Us
            </a>
          </div>

          <div className="flex justify-between mt-4 lg:mt-0">
            <div className="flex flex-col space-y-6">
              <span className="text-lg bold text-gp-grey-100">Support</span>
              <Link
                to="/learn"
                className="block text-sm font-normal text-gp-grey-100"
              >
                Learn
              </Link>
              <a
                href="https://goodpath.zendesk.com/hc/en-us/requests/new"
                className="block text-sm font-normal text-gp-grey-100"
              >
                Help
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="text-xs leading-tight">
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row md:justify-center md:items-center">
            <div className="md:mr-3">&copy;{`${currentYear} `} BICA Inc.</div>
            <div className="flex flex-col md:flex-row">
              <a
                href={'https://www.goodpath.com/terms'}
                target="_blank"
                rel={'noreferrer'}
                className="block my-3 font-normal text-gp-grey-100 md:mr-3"
              >
                Terms of Use
              </a>
              <a
                href={'https://www.goodpath.com/disclaimers'}
                target="_blank"
                rel={'noreferrer'}
                className="block my-3 font-normal text-gp-grey-100 md:mr-3"
              >
                Disclaimers
              </a>
              <a
                href={'https://www.goodpath.com/privacy'}
                target="_blank"
                rel={'noreferrer'}
                className="block my-3 font-normal text-gp-grey-100 md:mr-3"
              >
                Privacy Policy
              </a>
              <a
                href={'https://www.goodpath.com/affiliate-agreement'}
                target="_blank"
                rel={'noreferrer'}
                className="block my-3 font-normal text-gp-grey-100 md:mr-3"
              >
                Affiliate Agreement
              </a>
            </div>
          </div>

          {footerExtend}
        </div>
      </div>
    </footer>
  )
}
