import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeroImage from '../HeroImage'

export default function MapfreHeroImage() {
  const queryRes = useStaticQuery(query)

  return (
    <HeroImage
      largeImage={queryRes.lg}
      smallImage={queryRes.md}
      alt="A larger man smiling after using his Goodpath program he can now sleep 8 restful hours again and he has decreased back pain following new habit changes he learned."
    />
  )
}

const query = graphql`
  query mapfreHeroImageQuery {
    lg: file(name: { eq: "back-pain-man-112-lg" }) {
      name
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    md: file(name: { eq: "back-pain-man-112-md" }) {
      name
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
