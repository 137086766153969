import React from 'react'
import { TextColor } from './Types'

function EnrollSubheadline({
  text = 'white',
  children
}: EnrollSubheadlineProps) {
  return (
    <h3
      className={`text-gp-${text} text-3xl tracking-tight leading-snug md:text-4xl block`}
    >
      {children}
    </h3>
  )
}

interface EnrollSubheadlineProps {
  text?: TextColor
  children: React.ReactNode
}

export default EnrollSubheadline
