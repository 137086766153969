import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeroImage from '../HeroImage'

export default function YamahaHeroImage() {
  const queryRes = useStaticQuery(yamahaHeroImageQuery)

  return <HeroImage largeImage={queryRes.lg} smallImage={queryRes.md} />
}

const yamahaHeroImageQuery = graphql`
  query yamahaHeroImageQuery {
    lg: file(name: { eq: "man-no-stress-anxiety_lg" }) {
      name
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    md: file(name: { eq: "man-no-stress-anxiety_md" }) {
      name
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
