import React from 'react'
import EmbedVideo from '../common/EmbedVideo'
import GetStartedButton from './GetStartedButton'

export default function HowItWorksFloor({ ctaLink, videoId }: Props) {
  return (
    <div className="flex justify-center bg-primary-base">
      <div className="w-full p-10 lg:p-20 max-w-7xl">
        <div className="w-full max-w-3xl mx-auto">
          <div className="shadow-video ">
            <EmbedVideo videoId={videoId} autoplay={false} />
          </div>
        </div>
        <div className="justify-center hidden lg:flex ">
          <GetStartedButton ctaLink={ctaLink} />
        </div>
      </div>
    </div>
  )
}

type Props = {
  ctaLink: string
  videoId: string
}
