import React from 'react'
import HeroComponent from '../HeroComponent'
import MapfreHeroContent from './MapfreHeroContent'
import MapfreHeroImage from './MapfreHeroImage'
import MapfreLogo from './MapfreLogo'

export default function MapfreHero({ ctaLink }: Props) {
  return (
    <HeroComponent
      containerType="secondary"
      Logo={MapfreLogo}
      Image={MapfreHeroImage}
      content={<MapfreHeroContent ctaLink={ctaLink} />}
      widthRatioType="secondary"
    />
  )
}

type Props = {
  ctaLink: string
}
