import React from 'react'

function EnrollText({ children }: EnrollTextProps) {
  return <div className={`flex flex-col`}>{children}</div>
}

interface EnrollTextProps {
  children: React.ReactNode
}

export default EnrollText
