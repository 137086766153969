import React from 'react'
import GetStartedButton from '../../GetStartedButton'
import HeroContent from '../HeroContent'
import YamahaLogo from './YamahaLogo'

export default function YamahaHeroContent({ ctaLink }: Props) {
  return (
    <HeroContent
      Logo={YamahaLogo}
      title={
        <>
          <strong>Whole-person</strong>&nbsp;care <br /> for common conditions
        </>
      }
      subtitle={
        <>
          Complete care supported by a health coach, accessed online, and with
          treatments shipped to you.&nbsp;
          <strong>Fully-paid by Yamaha</strong>.
        </>
      }
      subNotice={
        <div className="-mt-4">
          Access to Goodpath treatment is fully-paid by Yamaha as a covered
          benefit available to employees and adult dependents
        </div>
      }
      Cta={GetStartedButton}
      ctaLink={ctaLink}
      conditionStripe
      watchHowItWorksLabel
    />
  )
}

type Props = {
  ctaLink: string
}
